import { all } from 'redux-saga/effects';
import CustomAddressSaga from '../components/Address/Saga';
import WhatsAppBusinessCampaignSaga from '../components/SendWhatsAppBusinessModal/Saga';
import AcceptCookieDialogSaga from '../containers/AcceptCookieDialog/AcceptCookieDialogSaga';
import ApiAccessPolicySaga from '../containers/ApiAccessPolicy/Saga';
import AuthenticationSaga from '../containers/Authentication/Sagas';
import CareerPageSaga from '../containers/CareerPage/Saga';
import companyEmployeeSaga from '../containers/CompanyEmployee/Saga';
import { ConfirmIndicationSaga } from '../containers/ConfirmIndication';
import CurriculumDownloadSaga from '../containers/CurriculumDownload/Saga';
import diversityLegalTermSaga from '../containers/DiversityLegalTerms/Saga';
import EmailDomainsSaga from '../containers/EmailDomains/Saga';
import { EmailTemplateSaga } from '../containers/EmailTemplate';
import { HomeSaga } from '../containers/Home';
import IndicatorsHomeInsightsSaga from '../containers/Home/components/NewHomeContent/components/IndicatorsHomeInsights/Saga';
import InsightsSaga from '../containers/Home/components/NewHomeContent/components/JobWeekInsights/Saga';
import { IntegrationsSaga } from '../containers/Integrations';
import { InterviewEventSaga as InterviewEventsSaga } from '../containers/InterviewEvents';
import { InterviewScriptSaga as InterviewScriptsSagaV2 } from '../containers/InterviewScripts';
import { CandidateInsertionSaga } from '../containers/Job/JobApplication/components/CandidateInsertion';
import CandidateTestsSaga from '../containers/Job/JobApplication/components/CurriculumDataTabTests/CandidateTestsSaga';
import HiringCelebrationSaga from '../containers/Job/JobApplication/components/HiringCelebrationModal/HiringCelebrationSagas';
import { HiringInformationSaga } from '../containers/Job/JobApplication/components/HiringInformationModal';
import InterviewEventSaga from '../containers/Job/JobApplication/components/InterviewEvent/InterviewEventSagas';
import JobApplicationHistorySaga from '../containers/Job/JobApplication/components/JobApplicationHistory/JobApplicationHistorySaga';
import OnboardingGaiaSaga from '../containers/Job/JobApplication/components/OnboardingGaia/OnboardingGaiaSaga';
import SkillsEvaluationSagaLegacy from '../containers/Job/JobApplication/components/SkillsEvaluationLegacy/SkillEvaluationLegacySaga';
import TimelineSaga from '../containers/Job/JobApplication/components/Timeline/TimelineSaga';
import JobCancelSaga from '../containers/Job/JobCancel/Saga';
import { JobClosureSaga } from '../containers/Job/JobClosure';
import JobDeletionSaga from '../containers/Job/JobDeletion/Saga';
import { JobOverviewSaga } from '../containers/Job/JobOverview';
import JobRegistrationFormSaga from '../containers/Job/JobStep/JobRegistrationForm/store/JobRegistrationFormSaga';
import JobAdditionalQuestionsSaga from '../containers/Job/JobStep/JobStepRegisterSetup/store/JobAdditionalQuestionsSaga';
import JobStepRegisterSaga from '../containers/Job/JobStep/JobStepRegisterSetup/store/JobStepRegisterSaga';
import JobStepAdditionalQuestionsSaga from '../containers/Job/JobStep/Saga';
import JobSummarySaga from '../containers/Job/JobSummary/Saga';
import { JobCustomFormTemplateSaga } from '../containers/JobCustomFormTemplate/Saga';
import JobOfferSaga from '../containers/JobOffer/JobOfferSaga';
import { JobOfferTemplateSaga } from '../containers/JobOfferTemplate';
import { JobTemplatesSaga } from '../containers/JobTemplates';
import MarketplaceApps from '../containers/MarketplaceApps/Sagas';
import NotificationsSagaV2 from '../containers/Notifications/NotificationsSagaV2';
import PreHireSaga from '../containers/PreHireStep/Saga';
import RealtimeSaga from '../containers/Realtime/RealtimeSaga';
import RootSetupSaga from '../containers/RootSetup/redux/RootSetupSaga';
import SetupEngageSurveySaga from '../containers/SetupEngageSurvey/SetupEngageSurveySaga';
import SetupHomePageSaga from '../containers/SetupHomePage/Saga';
import SetupOrganizationalStructureSaga from '../containers/SetupOrganizationalStructure/Saga';
import SocialMedias from '../containers/SocialMedias/Saga';
import TaskSaga from '../containers/Task/TaskSaga';
import userConsentSaga from '../containers/TermsAndPrivacy/saga';
import TermsAndPrivacyUpdateNoticeSaga from '../containers/TermsAndPrivacy/TermsAndPrivacyUpdateNotice/TermsAndPrivacyUpdateNoticeSaga';
import TestProvidersSaga from '../containers/TestSelection/TestProviders/TestProvidersSaga';
import { UserAccessProfileSaga } from '../containers/UserAccessProfile';
import UserProfileSaga from '../containers/UserProfile/Saga';
import WhatsappBusinessTemplateSaga from '../containers/WhatsappBusinessTemplate/Saga';
import WhatsAppSetupSaga from '../containers/WhatsAppSetup/Saga';
import { CustomTestSagas } from '../external/CustomTest';
import JobShareSaga from '../pages/JobShare/Saga';
import JobListSaga from '../pages/OldJobList/Saga';
import { ApprovalWorkflowSagas } from '../stores/modules/ApprovalWorkflow/ApprovalWorkflowSagas';
import AddressSaga from './Address/AddressSaga';
import CompanyTypesSaga from './CompanyTypes/CompanyTypesSaga';
import DepartmentSaga from './Department/DepartmentSaga';
import ForgotPasswordSaga from './ForgotPassword/ForgotPasswordSaga';
import InboundSaga from './Inbound/InboundSaga';
import JobApplicationSaga from './Job/JobApplication/JobApplicationSaga';
import JobCandidatesClassifiersSaga from './Job/JobCandidatesClassifiers/JobCandidatesClassifiersSaga';
import JobInternalDataSaga from './Job/JobInternalDataSaga';
import JobPicturesSaga from './Job/JobPictures/JobPicturesSaga';
import JobPublicationTypeSaga from './Job/JobPublicationType/JobPublicationTypeSaga';
import JobRatingCriteriasSaga from './Job/JobRatingCriterias/JobRatingCriteriasSaga';
import JobSaga from './Job/JobSaga';
import JobStepCustomSaga from './Job/JobStep/JobStepCustomSaga';
import JobStepFinalSaga from './Job/JobStep/JobStepFinalSaga';
import JobStepsSaga from './Job/JobStep/JobStepsSaga';
import JobWorkplaceSaga from './Job/JobWorkplace/JobWorkplaceSaga';
import ProfileSaga from './Profile/ProfileSaga';
import ProfileTestSaga from './ProfileTest/ProfileTestSaga';
import RoleSaga from './Role/RoleSaga';
import SkillSaga from './Skill/SkillSaga';
import SubsidiarySaga from './Subsidiary/SubsidiarySaga';

const customTestSagas = CustomTestSagas.map(saga => saga());

export default function* rootSaga() {
  yield all([
    AddressSaga(),
    CustomAddressSaga(),
    ApiAccessPolicySaga(),
    JobShareSaga(),
    AuthenticationSaga(),
    RoleSaga(),
    SocialMedias(),
    ProfileSaga(),
    SubsidiarySaga(),
    DepartmentSaga(),
    CompanyTypesSaga(),
    InboundSaga(),
    JobSaga(),
    JobListSaga(),
    JobPicturesSaga(),
    JobStepsSaga(),
    JobAdditionalQuestionsSaga(),
    JobWorkplaceSaga(),
    JobInternalDataSaga(),
    JobStepRegisterSaga(),
    JobStepCustomSaga(),
    JobStepFinalSaga(),
    JobCandidatesClassifiersSaga(),
    JobRatingCriteriasSaga(),
    JobApplicationSaga(),
    CandidateTestsSaga(),
    UserProfileSaga(),
    JobPublicationTypeSaga(),
    ProfileTestSaga(),
    ...customTestSagas,
    ForgotPasswordSaga(),
    EmailTemplateSaga(),
    JobOfferTemplateSaga(),
    JobTemplatesSaga(),
    JobOverviewSaga(),
    JobClosureSaga(),
    IntegrationsSaga(),
    UserAccessProfileSaga(),
    HiringInformationSaga(),
    InterviewScriptsSagaV2(),
    TestProvidersSaga(),
    CandidateInsertionSaga(),
    RealtimeSaga(),
    NotificationsSagaV2(),
    SetupOrganizationalStructureSaga(),
    SetupHomePageSaga(),
    ConfirmIndicationSaga(),
    ApprovalWorkflowSagas(),
    InterviewEventSaga(),
    InterviewEventsSaga(),
    RootSetupSaga(),
    JobRegistrationFormSaga(),
    HiringCelebrationSaga(),
    JobCustomFormTemplateSaga(),
    TaskSaga(),
    HomeSaga(),
    PreHireSaga(),
    MarketplaceApps(),
    JobStepAdditionalQuestionsSaga(),
    AcceptCookieDialogSaga(),
    EmailDomainsSaga(),
    CareerPageSaga(),
    JobCancelSaga(),
    JobDeletionSaga(),
    TermsAndPrivacyUpdateNoticeSaga(),
    IndicatorsHomeInsightsSaga(),
    InsightsSaga(),
    WhatsAppSetupSaga(),
    WhatsappBusinessTemplateSaga(),
    WhatsAppBusinessCampaignSaga(),
    diversityLegalTermSaga(),
    userConsentSaga(),
    companyEmployeeSaga(),
    CurriculumDownloadSaga(),
    JobSummarySaga(),
    JobApplicationHistorySaga(),
    TimelineSaga(),
    OnboardingGaiaSaga(),
    JobOfferSaga(),
    SetupEngageSurveySaga(),
    SkillsEvaluationSagaLegacy(),
    SkillSaga(),
  ]);
}
