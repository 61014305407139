import React from 'react';
import FooterWrapper from '../../../../components/FooterWrapper/FooterWrapper';
import DefaultAppFooter from '../AppFooter';

export const AppFooter = ({
  shouldDisplayEcosystemNav,
  footerData,
  shouldRenderHeaderAndFooter,
}) => {
  if (shouldDisplayEcosystemNav && footerData)
    return <FooterWrapper data={footerData} />;
  return shouldRenderHeaderAndFooter && <DefaultAppFooter />;
};
