import { Spinner } from '@gupy/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  openConfirmDialog as openConfirmDialogRequest,
  updateGlobalization,
  updateNetworkStatus,
} from '../../../../actions/App/AppActions';
import featuresFlagsEnum from '../../../Authentication/FeaturesFlagsEnum';
import DiversityLegalTermsContainer from '../../../DiversityLegalTerms/Container';
import { isInsideIframe } from '../helpers/isInsideIframe';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { MainLayoutSpinner } from './MainLayout.styles';
import { useLoadEcosystemNav } from './useLoadEcosystemNav';

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  openConfirmDialog: PropTypes.func,
};

const MainLayout = ({
  children,
  Authentication: { currentUser },
  openConfirmDialog,
}) => {
  const shouldRenderHeaderAndFooter = !isInsideIframe();
  const defaultHeaderHeight = '64px';
  const defaultSidebarWidth = '92px';
  const defaultMainMinHeight = '100vmin';
  const shouldDisplayProductAlert = useFlag(featuresFlagsEnum.productAlert);
  const appMenuRef = useRef();
  const shouldDisplayEcosystemNav = useFlag(featuresFlagsEnum.navEcosystem);
  const [menuHeight, updateMenuHeight] = useState(defaultHeaderHeight);
  const [hasAppAlarmDismissed, setHasAppAlarmDismissed] = useState(false);
  const {
    footerData,
    modalData,
    navbarData,
    sidebarData,
    isEcosystemLoading,
    ecosystemLoadError,
  } = useLoadEcosystemNav(currentUser);
  const isEnabledZendesk = useFlag(featuresFlagsEnum.widgetZendeskCompanies);
  const isEnabledNewNavBar = useFlag(featuresFlagsEnum.newNavEcosystem);

  const beamerButton = document.querySelector('#beamerSelector');

  const buttonChat = document.querySelector('#embedded-messaging');

  useEffect(() => {
    const appMenu = appMenuRef.current;
    const menuObserve = () => {
      const header = appMenu.firstElementChild;
      const headerHeight = header.getBoundingClientRect().height;
      if (headerHeight > 0) updateMenuHeight(`${headerHeight}px`);
    };
    if (beamerButton && isEnabledNewNavBar) {
      beamerButton.style.display = 'none';
    }
    if (buttonChat && isEnabledNewNavBar) {
      buttonChat.style.display = 'none';
    }

    const observer = new MutationObserver(menuObserve);
    const observerConfig = {
      childList: true,
      subtree: true,
    };
    if (appMenu) {
      observer.observe(appMenu, observerConfig);
    }
    return () => observer.disconnect();
  }, []);

  const isEcosystemOnDisplay = shouldDisplayEcosystemNav && !ecosystemLoadError;

  const mainLayoutStyle = isEcosystemOnDisplay
    ? {
        paddingLeft: window.innerWidth > 1024 && defaultSidebarWidth,
        paddingTop:
          shouldDisplayProductAlert && !hasAppAlarmDismissed ? '102px' : '64px', // FIXME: Deve calcular dinamicamente a altura do DefaultSystemAlert
        backgroundColor: '#f9f9f9',
        minHeight: defaultMainMinHeight,
      }
    : { paddingTop: menuHeight };

  if (shouldDisplayEcosystemNav && isEcosystemLoading) {
    return (
      <MainLayoutSpinner className="main-layout-spinner">
        <Spinner size={60} />
      </MainLayoutSpinner>
    );
  }

  return (
    <Fragment>
      <DiversityLegalTermsContainer />
      <AppMenu
        currentUser={currentUser}
        isEcosystemOnDisplay={isEcosystemOnDisplay}
        navbarData={navbarData}
        sidebarData={sidebarData}
        modalData={modalData}
        isEcosystemLoading={isEcosystemLoading}
        hasAppAlarmDismissed={hasAppAlarmDismissed}
        setHasAppAlarmDismissed={setHasAppAlarmDismissed}
        appMenuRef={appMenuRef}
        openConfirmDialog={openConfirmDialog}
        shouldRenderHeaderAndFooter={shouldRenderHeaderAndFooter}
        isEnabledZendesk={isEnabledZendesk}
        isEnabledNewNavBar={isEnabledNewNavBar}
      />
      <main data-testid="main" style={mainLayoutStyle}>
        {children}
      </main>
      <AppFooter
        footerData={footerData}
        shouldDisplayEcosystemNav={shouldDisplayEcosystemNav}
        shouldRenderHeaderAndFooter={shouldRenderHeaderAndFooter}
      />
    </Fragment>
  );
};

MainLayout.propTypes = propTypes;

const mapStateToProps = state => ({
  Authentication: state.reducers.Authentication,
});

export default withRouter(
  connect(mapStateToProps, {
    openConfirmDialog: openConfirmDialogRequest,
    updateGlobalization,
    updateNetworkStatus,
  })(MainLayout),
);
