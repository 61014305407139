import { Button } from '@gupy/eco-design-system';
import { useFlag } from '@unleash/proxy-client-react';
import React, { Fragment, useEffect, useState } from 'react';
import featuresFlagsEnum from '../../containers/Authentication/FeaturesFlagsEnum';
import ContentModal from './ContentModalDiscoveryProductsWrapper';
import ModalDiscoveryProductsStyles from './ModalDiscoveryProductsWrapper.styles';

const ModalDiscoveryProductsWrapper = ModalDiscoveryProductsTag => {
  const { modalData, mobileProductNavDisplay, setMobileProductNavDisplay } =
    ModalDiscoveryProductsTag;

  const { Modal, ProductsList, GupyProduct, ProductTitleTab } =
    ModalDiscoveryProductsStyles;
  const isEnabledNewNavBar = useFlag(featuresFlagsEnum.newNavEcosystem);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelectTab, currentSelectTab] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const isDesktop = screenSize > 1024;

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleModalButtonClick = () => {
    if (isDesktop) {
      setIsModalOpen(true);
    } else if (mobileProductNavDisplay === 'none') {
      setMobileProductNavDisplay('block');
    } else {
      setMobileProductNavDisplay('none');
    }
  };

  const buttonOpenModal = () => {
    if (isDesktop) {
      return (
        <Button
          color="secondary"
          variant="outlined"
          size="large"
          onClick={handleModalButtonClick}
        >
          <img
            alt=""
            src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/modal-icons/icon-dragDrop.svg`}
          />
          Conhecer mais produtos
        </Button>
      );
    }
    return (
      <Button
        color="secondary"
        variant="text"
        size="large"
        onClick={handleModalButtonClick}
      >
        <img
          alt=""
          src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/modal-icons/icon-dragDrop.svg`}
        />
      </Button>
    );
  };

  return (
    <Fragment>
      {modalData && (
        <Fragment>
          <div className="button-modal-discovery-products">
            {isEnabledNewNavBar ? (
              <Button
                color="secondary"
                variant="outlined"
                size="large"
                onClick={handleModalButtonClick}
              >
                Potencializar meu RH
              </Button>
            ) : (
              buttonOpenModal()
            )}
          </div>

          <Modal
            {...modalData.styleModal}
            open={isModalOpen}
            hasCloseButton
            onOpenChange={value => setIsModalOpen(value)}
            id="modal-discovery-products"
            maxWidth="lg"
            title={modalData.titleModal}
            onClose={() => setIsModalOpen(false)}
          >
            {modalData.tabsContent && (
              <Fragment>
                <ProductsList {...modalData.styleProductsList}>
                  <GupyProduct {...modalData.styleGupyProduct}>
                    {modalData.tabsContent.map(discoveryProduct => (
                      <ProductTitleTab
                        items={[{ title: `${discoveryProduct.tabsName}` }]}
                        ariaLabel={discoveryProduct.tabsName}
                        id={discoveryProduct.id}
                        onClick={() =>
                          currentSelectTab(
                            modalData.tabsContent.findIndex(
                              tab => tab.id === discoveryProduct.id,
                            ),
                          )
                        }
                        hasBoxShadow={false}
                        tabIndex={discoveryProduct.id}
                      />
                    ))}
                  </GupyProduct>
                </ProductsList>
                <ContentModal
                  currentContent={modalData.tabsContent[isSelectTab]}
                  data={modalData}
                  styleModalContent={modalData.styleModalContent}
                  styleModalDiv={modalData.styleModalDiv}
                  styleModalContentList={modalData.styleModalContentList}
                  styleFooterModalContent={modalData.styleFooterModalContent}
                />
              </Fragment>
            )}
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ModalDiscoveryProductsWrapper;
