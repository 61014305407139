import api from '../../api/DarthVaderClient';

export default {
  getTags() {
    return api.get('/selection-process/company/tag');
  },
  getTagsByName(name, limit) {
    return api.get(
      `/selection-process/company/tag?name=${name}&limit=${limit}`,
    );
  },
  getTagsNameByJob(jobId) {
    return api.get(`/selection-process/company/job/${jobId}/tag?fields=name`);
  },
  getJobTagsByName(jobId, name) {
    return api.get(
      `/selection-process/company/job/${jobId}/tag?name=${name}&limit=10`,
    );
  },
  getTagsName() {
    return api.get('/selection-process/company/tag?fields=name');
  },
  getTypes() {
    return api.get(
      '/company-management/companies/types?order=name&page=0&perPage=9999',
    );
  },
  closeProfileTest() {
    return api.post('/profile-test/companies/profiles/tests/close');
  },
};
