import JobActionTypes from '../../constants/Job/JobActionTypes';
import JobDetailsActionTypes from '../../constants/Job/JobDetailsActionTypes';
import JobPermissionsManager from '../../managers/JobPermissionsManager';

const adaptSummary = (summary, isAdvancedSearchDisabled) => {
  const needAdaptSearch =
    !isAdvancedSearchDisabled &&
    summary.filter &&
    summary.filter.search &&
    summary.filter.search.indexOf(':') < 0;

  if (needAdaptSearch) {
    const adaptedSearch = `code: *${summary.filter.search}* OR name: ${summary.filter.search} OR position: *${summary.filter.search}*`;
    const filter = {
      ...summary.filter,
      search: adaptedSearch,
    };
    return {
      ...summary,
      filter,
    };
  }
  return summary;
};

const initJobDetails = jobId => ({
  type: JobDetailsActionTypes.INIT_JOB_DETAILS,
  jobId,
});

const getAllJobs = (summary, isAdvancedSearchDisabled) => ({
  type: JobActionTypes.GET_ALL,
  summary,
  isAdvancedSearchDisabled,
  adaptedSummary: adaptSummary(summary, isAdvancedSearchDisabled),
});

const getAllJobsSuccess = (jobList, filters) => ({
  type: JobActionTypes.GET_ALL_SUCCESS,
  jobList: {
    summary: jobList.summary,
    data: JobPermissionsManager.adaptJobsPermissions(jobList.data),
  },
  statusList: filters.status,
  recruiters: filters.recruiters
    ? filters.recruiters.map(recruiter => ({
        value: recruiter.id.toString(),
        label: recruiter.name,
      }))
    : [],
});

const changeJobListSummary = (summary, isAdvancedSearchDisabled) => ({
  type: JobActionTypes.CHANGE_SUMMARY,
  summary,
  isAdvancedSearchDisabled,
  adaptedSummary: adaptSummary(summary, isAdvancedSearchDisabled),
});

const changeJobListSummaryWithoutRequest = summary => ({
  type: JobActionTypes.CHANGE_SUMMARY_WITHOUT_REQUEST,
  summary,
});

const clearJobListSummary = () => ({
  type: JobActionTypes.CLEAR_SUMMARY,
});

const changeForm = modelForm => ({
  type: JobDetailsActionTypes.CHANGE_FORM,
  modelForm,
});

const clearForm = () => ({
  type: JobDetailsActionTypes.CLEAR_FORM,
});

const postJobDetails = (payload, jobPath) => ({
  type: JobDetailsActionTypes.POST,
  payload,
  jobPath,
});

const postJobWithoutValidation = (payload, jobPath) => ({
  type: JobDetailsActionTypes.POST_NO_VALIDATION,
  payload,
  jobPath,
});

const patchJobDetails = (payload, jobPath) => ({
  type: JobDetailsActionTypes.PATCH,
  payload,
  jobPath,
});

const patchJobWithoutValidation = (payload, jobPath, messages) => ({
  type: JobDetailsActionTypes.PATCH_NO_VALIDATION,
  payload,
  jobPath,
  messages,
});

const patchJobNameValidation = (payload, jobPath) => ({
  type: JobDetailsActionTypes.PATCH_NAME_VALIDATION,
  payload,
  jobPath,
});

const getJobDetails = id => ({
  type: JobDetailsActionTypes.GET,
  id,
});

const getJobDetailsSuccess = job => ({
  type: JobDetailsActionTypes.GET_SUCCESS,
  modelForm: JobPermissionsManager.adaptJobPermissions(job),
});

const pinJob = (jobId, summary) => ({
  type: JobActionTypes.PIN,
  jobId,
  summary,
});

const unpinJob = (jobId, summary) => ({
  type: JobActionTypes.UNPIN,
  jobId,
  summary,
});

const getJobTypes = () => ({
  type: JobDetailsActionTypes.GET_JOB_TYPES,
});

const getJobReasonTypes = () => ({
  type: JobDetailsActionTypes.GET_JOB_REASON_TYPES,
});

const getJob = id => ({
  type: JobActionTypes.GET,
  id,
});

const getJobSuccess = job => ({
  type: JobActionTypes.GET_SUCCESS,
  model: JobPermissionsManager.adaptJobPermissions(job),
});

const clearSecondaryMenuForm = quickApply => ({
  type: JobActionTypes.CLEAR_FORM,
  quickApply,
});

const deleteJob = (jobId, summary, jobPath, messages) => ({
  type: JobActionTypes.DELETE,
  jobId,
  summary,
  jobPath,
  messages,
});

const duplicateJob = (jobId, jobPath, messages) => ({
  type: JobActionTypes.DUPLICATE,
  jobId,
  jobPath,
  messages,
});

const duplicateJobAsTemplate = (jobId, messages) => ({
  type: JobActionTypes.DUPLICATE_AS_TEMPLATE,
  jobId,
  messages,
});

const patchJobStatus = (payload, summary, jobPath, messages) => ({
  type: JobActionTypes.PATCH_JOB_STATUS,
  payload,
  summary,
  jobPath,
  messages,
});

const getCompanyCareerPages = () => ({
  type: JobActionTypes.GET_COMPANY_CAREER_PAGES,
});

const getCompanyCareerPagesSuccess = response => ({
  type: JobActionTypes.GET_COMPANY_CAREER_PAGES_SUCCESS,
  defaultCareerPageId: response.length === 1 ? response[0].id.toString() : '',
  careerPages: response.map(page => ({
    value: page.id.toString(),
    label: page.name,
    visibility: page.visibility,
  })),
});

const getJobAnalysis = payload => ({
  type: JobActionTypes.GET_JOB_ANALYSIS,
  payload,
});

const clearJobAnalysis = payload => ({
  type: JobActionTypes.CLEAR_JOB_ANALYSIS,
  payload,
});

const openJobFieldFilledModal = () => ({
  type: JobActionTypes.OPEN_JOB_FIELD_FILLED_MODAL,
});

const closeJobFieldFilledModal = () => ({
  type: JobActionTypes.CLOSE_JOB_FIELD_FILLED_MODAL,
});

export {
  initJobDetails,
  getAllJobs,
  getAllJobsSuccess,
  changeJobListSummary,
  changeJobListSummaryWithoutRequest,
  clearJobListSummary,
  changeForm,
  clearForm,
  postJobDetails,
  postJobWithoutValidation,
  patchJobDetails,
  patchJobWithoutValidation,
  patchJobNameValidation,
  getJobDetails,
  getJobDetailsSuccess,
  pinJob,
  unpinJob,
  getJobTypes,
  getJobReasonTypes,
  getJob,
  getJobSuccess,
  clearSecondaryMenuForm,
  deleteJob,
  duplicateJob,
  duplicateJobAsTemplate,
  patchJobStatus,
  getCompanyCareerPages,
  getCompanyCareerPagesSuccess,
  getJobAnalysis,
  clearJobAnalysis,
  openJobFieldFilledModal,
  closeJobFieldFilledModal,
};
