import { BaseForm } from '@gupy/front-commons';

export const isResponsibleUsersError = (error, messages) => {
  if (
    error &&
    error.body &&
    error.body.errors &&
    error.body.errors.length > 0
  ) {
    const responsiblesValidation = {};
    const { errors } = error.body;
    const targetMessages = {
      recruiter: 'Invalid companyId from recruiter',
      manager: 'Invalid companyId from manager',
    };

    for (let i = 0; i < errors.length; i += 1) {
      const currentError = errors[i];

      if (currentError.message === targetMessages.recruiter) {
        responsiblesValidation.recruiterId = {
          message: messages.reviewJobResponsible,
          type: 'error',
        };
      }

      if (currentError.message === targetMessages.manager) {
        responsiblesValidation.managerId = {
          message: messages.reviewJobResponsible,
          type: 'error',
        };
      }
    }
    return responsiblesValidation;
  }

  return null;
};

class JobInternalDataForm extends BaseForm {
  constructor() {
    super({
      numVacancies: {
        validate: {
          greaterOrEqualTo: 1,
        },
      },
    });
  }
}

export default JobInternalDataForm;
