import { Button, Menu, MenuItem } from '@gupy/eco-design-system';
import React, { Fragment, useState } from 'react';

const DiscoveryProducts = ModalDiscoveryProductsTag => {
  const { modalData, data, isCurrentProduct } = ModalDiscoveryProductsTag;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleModalButtonClick = event => {
    setIsMenuOpen(event.currentTarget);
  };
  const handleClose = () => {
    setIsMenuOpen(null);
  };

  return (
    <Fragment>
      {modalData && (
        <Fragment>
          <div className="button-modal-discovery-products">
            <Button
              color="inherit"
              size="medium"
              variant="text"
              onClick={handleModalButtonClick}
            >
              <img
                alt=""
                src={`${process.env.REACT_APP_ASSETS_URL}/sidebar/productListNewIcon.svg`}
              />
            </Button>
          </div>
          {
            <Menu
              anchorEl={isMenuOpen}
              open={Boolean(isMenuOpen)}
              onClose={handleClose}
              menuVariant="groupedItems"
              placement="bottom-end"
              autoWidth
              groups={[
                {
                  label: 'Seus Produtos',
                  items: [
                    data.products.map(product =>
                      isCurrentProduct(product) ? (
                        <MenuItem selected>
                          <a href={product.link} target="_blank">
                            {product.title}
                          </a>
                        </MenuItem>
                      ) : (
                        <MenuItem
                          key={product.id}
                          eventAction={product.eventAction}
                        >
                          <a
                            {...data.styleProductTitle}
                            href={product.link}
                            target="_blank"
                          >
                            {product.title}
                          </a>
                        </MenuItem>
                      ),
                    ),
                  ],
                },
                {
                  label: 'Potencializar meu RH',
                  items: [
                    modalData.tabsContent.map(discoveryProduct => (
                      <MenuItem>
                        <a
                          {...data.styleProductTitle}
                          id={discoveryProduct.id}
                          href={discoveryProduct.link}
                          target="_blank"
                        >
                          {discoveryProduct.tabsName}
                        </a>
                      </MenuItem>
                    )),
                  ],
                },
              ]}
            />
          }
        </Fragment>
      )}
    </Fragment>
  );
};

export default DiscoveryProducts;
