import JobInternalDataActionTypes from '../../constants/Job/JobInternalDataActionTypes';
import JobPermissionsManager from '../../managers/JobPermissionsManager';

export const initJobInternalData = jobId => ({
  type: JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA,
  jobId,
});

export const initJobInternalDataSuccess = (
  jobApproversList,
  jobRecruitersList,
  jobReasonTypes,
  job,
  jobManagersList,
  onlyDefaultManagers = false,
  onlyDefaultRecruiters = false,
) => ({
  type: JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS,
  jobApproversList,
  jobRecruitersList,
  jobReasonTypes,
  modelForm: JobPermissionsManager.adaptJobPermissions(job),
  jobManagersList,
  onlyDefaultManagers,
  onlyDefaultRecruiters,
});

export const changeForm = modelForm => ({
  type: JobInternalDataActionTypes.CHANGE_FORM,
  modelForm,
});

export const clearForm = () => ({
  type: JobInternalDataActionTypes.CLEAR_FORM,
});

export const patchJobInternalData = (payload, jobPath, form, messages) => ({
  type: JobInternalDataActionTypes.PATCH,
  payload,
  jobPath,
  form,
  messages,
});
