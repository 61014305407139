import { NetworkAlert } from '@gupy/front-commons';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import AppBar from '@material-ui/core/AppBar';
import { useFlag } from '@unleash/proxy-client-react';
import React, { Fragment, useEffect, useState } from 'react';
import Copilot from '../../../../components/Copilot/Copilot';
import NavbarWrapper from '../../../../components/NavBarWrapper/NavBarWrapper';
import NewNavbar from '../../../../components/NavBarWrapper/NewNavBar/NewNavBar';
import SidebarWrapper from '../../../../components/SidebarWrapper/SidebarWrapper';
import featuresFlagsEnum from '../../../Authentication/FeaturesFlagsEnum';
import DefaultAppMenu from '../DefaultAppMenu/DefaultAppMenu';
import { Ecosystem, MobileSidebarMenuOverlay } from './MainLayout.styles';
import { SystemAlert } from './SystemAlert';

export const AppMenu = ({
  isEcosystemOnDisplay,
  currentUser,
  navbarData,
  modalData,
  sidebarData,
  shouldRenderHeaderAndFooter,
  hasAppAlarmDismissed,
  setHasAppAlarmDismissed,
  appMenuRef,
  openConfirmDialog,
}) => {
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [isOpenCopilot, setOpenCopilot] = useState(false);

  const isEnabledCopilot = useFlag(featuresFlagsEnum.copilot);
  const isEnabledNewNavBar = useFlag(featuresFlagsEnum.newNavEcosystem);
  const isEnabledZendesk = useFlag(featuresFlagsEnum.widgetZendeskCompanies);
  const isSalesforceEnabled = useFlag(featuresFlagsEnum.salesforceChatWidget);

  const beamerButton = document.querySelector('#beamerSelector');

  const buttonChat = document.querySelector('#embedded-messaging');
  const ocultButtons = () => {
    if (beamerButton && isEnabledNewNavBar) {
      beamerButton.style.display = 'none';
    }
    if (buttonChat && isEnabledNewNavBar) {
      buttonChat.style.display = 'none';
    }
  };

  useEffect(() => {
    if (beamerButton && isEnabledNewNavBar) {
      beamerButton.style.display = 'none';
    }
    if (buttonChat && isEnabledNewNavBar) {
      buttonChat.style.display = 'none';
    }
  }, [buttonChat, beamerButton, isEnabledNewNavBar]);

  const GA_ACTIONS = {
    openCopilot: 'copilot-click-to-open',
  };

  const sendMetricsToGA = action => {
    GoogleAnalyticsHelper.sendEvent({
      category: `COPILOT_CLICK_${action === GA_ACTIONS.openCopilot ? 'TO_OPEN' : 'TO_CLOSE'}`,
      action,
    });
  };

  const handleCopilot = () => {
    setOpenCopilot(true);
    sendMetricsToGA(GA_ACTIONS.openCopilot);
  };

  if (isEcosystemOnDisplay) {
    ocultButtons();

    return (
      <Fragment>
        <Ecosystem>
          <NetworkAlert onChange={() => {}} />
          <SystemAlert
            currentUser={currentUser}
            hasAppAlarmDismissed={hasAppAlarmDismissed}
            setHasAppAlarmDismissed={setHasAppAlarmDismissed}
          />
          {isEnabledNewNavBar ? (
            <NewNavbar
              id="navbar"
              currentUser={currentUser}
              data={navbarData}
              modalData={modalData}
              setMobileSidebarOpen={setMobileSidebarOpen}
              handleCopilot={handleCopilot}
              setOpenCopilot={setOpenCopilot}
              isEnabledZendesk={isEnabledZendesk}
              isSalesforceEnabled={isSalesforceEnabled}
            />
          ) : (
            <NavbarWrapper
              id="navbar"
              currentUser={currentUser}
              data={navbarData}
              modalData={modalData}
              setMobileSidebarOpen={setMobileSidebarOpen}
            />
          )}
          <SidebarWrapper
            id="sidebar-container"
            currentUser={currentUser}
            data={sidebarData}
            isMobileSidebarOpen={isMobileSidebarOpen}
            setMobileSidebarOpen={setMobileSidebarOpen}
          />
          <MobileSidebarMenuOverlay
            style={
              isMobileSidebarOpen && window.innerWidth < 1024
                ? { display: 'block' }
                : { display: 'none' }
            }
          />
        </Ecosystem>
        {isEnabledCopilot ? (
          <Copilot
            currentUser={currentUser}
            isOpenCopilot={isOpenCopilot}
            setOpenCopilot={setOpenCopilot}
            isEnabledButtonsNavBar={isEnabledNewNavBar}
          />
        ) : null}
      </Fragment>
    );
  }

  return (
    shouldRenderHeaderAndFooter && (
      <div ref={appMenuRef} data-testid="default-navigation">
        <AppBar
          elevation={0}
          classes={{
            root: 'mainLayout',
          }}
        >
          <NetworkAlert onChange={() => {}} />
          <SystemAlert
            currentUser={currentUser}
            hasAppAlarmDismissed={hasAppAlarmDismissed}
            setHasAppAlarmDismissed={setHasAppAlarmDismissed}
          />
          <DefaultAppMenu
            currentUser={currentUser}
            openConfirmDialog={openConfirmDialog}
          />
          {isEnabledCopilot ? (
            <Copilot
              currentUser={currentUser}
              isOpenCopilot={isOpenCopilot}
              setOpenCopilot={setOpenCopilot}
              isEnabledNewNavBar={isEnabledNewNavBar}
            />
          ) : null}
        </AppBar>
      </div>
    )
  );
};
