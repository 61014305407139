import { Button } from '@gupy/eco-design-system';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ModalDiscoveryProducts from '../../ModalDiscoveryProductsWrapper/ModalDiscoveryProductsWrapper';
import DiscoveryProducts from '../../ModalDiscoveryProductsWrapper/newDiscoveryProducts/DiscoveryProducts';
import NewNavBarStyles from './NewNavBar.styles';

const propTypes = {
  handleCopilot: PropTypes.func,
  setOpenCopilot: PropTypes.bool,
};
const NewNavbar = NavbarWrapperTag => {
  const {
    data,
    modalData,
    setMobileSidebarOpen,
    handleCopilot,
    isEnabledZendesk,
    isSalesforceEnabled,
  } = NavbarWrapperTag;

  const {
    NavBarHeader,
    NavbarProducts,
    ProductDiv,
    ProductsList,
    FixedProduct,
    ProductTitle,
    GupyProduct,
    FooterNavBar,
    ButtonOpenSidebar,
  } = NewNavBarStyles;
  const [mobileProductNavDisplay, setMobileProductNavDisplay] =
    useState('none');
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const isCurrentProduct = product =>
    product.name === 'navbar.recruitmentAndSelection';

  const sendGAEvent = eventAction => {
    GoogleAnalyticsHelper.sendEvent({
      category: 'HEADER_GLOBAL',
      action: eventAction,
    });
  };
  const buttonZendesk = window.zE;
  const buttonSalesforce = document.querySelector('#embedded-messaging');

  const buttonsWidgetChat = () => {
    if (isEnabledZendesk && window.zE) {
      buttonZendesk.open();
    }
    if (isSalesforceEnabled) {
      buttonSalesforce.eventAction('click');
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const ProductsModal = () => {
    const isDesktop = screenSize > 1319;
    const isMobile = screenSize <= 1318;
    if (isDesktop && data.products.length < 3) {
      return (
        <FooterNavBar {...data.styleFooterNavBar}>
          <ModalDiscoveryProducts
            modalData={modalData}
            mobileProductNavDisplay={mobileProductNavDisplay}
            setMobileProductNavDisplay={setMobileProductNavDisplay}
          />
        </FooterNavBar>
      );
    }
    if (isMobile) {
      return (
        <DiscoveryProducts
          modalData={modalData}
          data={data}
          isCurrentProduct={isCurrentProduct}
        ></DiscoveryProducts>
      );
    }

    return false;
  };
  const ButtonsChats = () => {
    if (window.innerWidth < 600 || data.products != null) {
      return (
        <FooterNavBar {...data.styleFooterNavBar}>
          <Fragment>
            <Button
              color="inherit"
              onClick={handleCopilot}
              size="medium"
              variant="text"
            >
              <img
                alt=""
                src={`${process.env.REACT_APP_ASSETS_URL}/sidebar/newIconCopilot.svg`}
              />
            </Button>
            {
              <Button
                color="inherit"
                onClick={buttonsWidgetChat}
                size="medium"
                variant="text"
              >
                <img
                  alt=""
                  src={`${process.env.REACT_APP_ASSETS_URL}/sidebar/chatNewIcon.svg`}
                />
              </Button>
            }
            <Button
              color="inherit"
              onClick={() => {
                if (window.Beamer) {
                  window.Beamer.show();
                }
              }}
              size="medium"
              variant="text"
            >
              <img
                alt=""
                src={`${process.env.REACT_APP_ASSETS_URL}/sidebar/beamerNewIcon.svg`}
              />
            </Button>
          </Fragment>
        </FooterNavBar>
      );
    }
    return false;
  };

  const toggleSidebar = () => {
    setMobileSidebarOpen(prevState => !prevState);
  };

  const mobileProductNavRef = useRef();
  useEffect(() => {
    const checkIfClickedOutsideMobileProductNav = e => {
      if (
        mobileProductNavDisplay === 'block' &&
        mobileProductNavRef.current &&
        !mobileProductNavRef.current.contains(e.target)
      ) {
        setMobileProductNavDisplay('none');
      }
    };
    document.addEventListener(
      'mousedown',
      checkIfClickedOutsideMobileProductNav,
    );
  });

  return data && data.styleNavbarHeader ? (
    <header>
      <NavBarHeader {...data.styleNavbarHeader} data-testid="navbar-ecosystem">
        <ButtonOpenSidebar
          variant="text"
          size="sm"
          onClick={toggleSidebar}
          aria-label="Menu"
          alt="Menu"
          icon={
            <img
              alt=""
              src={`${process.env.REACT_APP_ASSETS_URL}/sidebar/icon-open-sidebar.svg`}
            />
          }
          data-testid="navbar-ecosystem"
        />
        <img
          src={`${process.env.REACT_APP_ASSETS_URL}/sidebar/logo-GupyR&S.svg`}
          alt="Logo"
          className="rs-logo"
        />
        <NavbarProducts {...data.styleNavbarProducts}>
          <ProductDiv {...data.styleProductDiv}>
            <a href="#" onClick={() => sendGAEvent('click_logotipo_gupy')}>
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/gupy-logo/gupy-logo.svg`}
                alt="Logo"
                className="navbar-logo"
              />
            </a>
            <ProductsList {...data.styleProductsList}>
              {data.products && (
                <Fragment>
                  {data.products.map(product =>
                    isCurrentProduct(product) ? (
                      <FixedProduct {...data.styleFixedProduct}>
                        <ProductTitle
                          href="#"
                          {...data.styleProductTitle}
                          rel="noreferrer"
                          target="_blank"
                          onClick={() =>
                            sendGAEvent(data.fixedProduct.eventAction)
                          }
                        >
                          {product.title}
                        </ProductTitle>
                      </FixedProduct>
                    ) : (
                      <GupyProduct
                        key={product.id}
                        {...data.styleGupyProduct}
                        onClick={() => sendGAEvent(product.eventAction)}
                      >
                        <ProductTitle
                          href={product.link}
                          {...data.styleProductTitle}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {product.title}
                        </ProductTitle>
                      </GupyProduct>
                    ),
                  )}
                </Fragment>
              )}
            </ProductsList>
          </ProductDiv>
          {ProductsModal()}
        </NavbarProducts>
        <ButtonsChats />
      </NavBarHeader>
    </header>
  ) : null;
};

NewNavbar.prototypes = propTypes;
export default NewNavbar;
