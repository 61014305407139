import { tokens } from '@gupy/design-system-v2';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl-redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { getQueryClient } from '../../query-client/client';
import 'react-toastify/dist/ReactToastify.min.css';
import '../styles/companies.css';
import history from '../history';
import App from './App/App';
import BeamerInjector from './BeamerInjector';
import ChatInjector from './ChatInjector';
import GlobalErrorHandling from './Error/GlobalErrorHandling';
import IDPProvider from './IDPProvider';
import ReCaptchaInjector from './ReCaptchaInjector';
import UnleashProvider from './UnleashProvider';

const propTypes = {
  store: PropTypes.object.isRequired,
};

const queryClient = getQueryClient();
const env = process.env.NODE_ENV === 'development';

const Root = ({ store }) => (
  <Provider store={store}>
    <IntlProvider defaultLocale="pt">
      <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
        <ThemeProvider theme={tokens}>
          <UnleashProvider>
            <Router history={history}>
              <QueryClientProvider client={queryClient}>
                <GlobalErrorHandling>
                  <IDPProvider>
                    <App />
                    <ReactQueryDevtools initialIsOpen={env} />
                  </IDPProvider>
                </GlobalErrorHandling>
              </QueryClientProvider>
            </Router>
            <ChatInjector />
            <BeamerInjector />
          </UnleashProvider>
        </ThemeProvider>
        <ReCaptchaInjector />
      </MuiPickersUtilsProvider>
    </IntlProvider>
  </Provider>
);

Root.propTypes = propTypes;

export default Root;
