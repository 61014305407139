import React from 'react';
import DefaultSystemAlert from '../DefaultSystemAlert';

export const SystemAlert = ({
  currentUser,
  hasAppAlarmDismissed,
  setHasAppAlarmDismissed,
}) => (
  <DefaultSystemAlert
    currentUser={currentUser}
    onDismiss={setHasAppAlarmDismissed}
    dismiss={hasAppAlarmDismissed}
  />
);
