import {
  adaptGeolocationFromHere,
  buildToast,
  ToastTypes,
} from '@gupy/front-commons';
import CandidateProfileDetailsHelper from '@gupy/front-helpers/src/helpers/CandidateProfileDetailsHelper';
import FeebackAnalyticsInfoTypes from '../../../constants/Job/JobApplication/FeebackAnalyticsInfoTypes';
import JobApplicationActionTypes from '../../../constants/Job/JobApplication/JobApplicationActionTypes';
import JobApplicationCurrentStepType from '../../../constants/Job/JobApplication/JobApplicationCurrentStepType';
import JobStepTypes from '../../../constants/Job/JobStep/JobStepTypes';
import UrlHelper from '../../../helpers/UrlHelper';
import JobPermissionsManager from '../../../managers/JobPermissionsManager';

const sum = (arr, prop) => arr.reduce((acc, val) => acc + val[`${prop}`], 0);

const getAllApplicationsCount = arr => sum(arr, 'applicationsCount');

const getStatusCount = (statusName, statusList) => {
  const found = statusList.find(status => status.name === statusName);
  return (found && found.applicationsCount) || 0;
};

const buildFeedbackMessage = ({
  nextStep,
  feedbackEmailOptions,
  applicationIds,
  ghostApplicationIds,
}) =>
  nextStep.type === JobApplicationCurrentStepType.reproved &&
  feedbackEmailOptions &&
  feedbackEmailOptions.template &&
  feedbackEmailOptions.template.template
    ? {
        allowReply: feedbackEmailOptions.template.allowReply,
        applicationIds: applicationIds.filter(
          id => ghostApplicationIds.indexOf(id) === -1,
        ),
        emailBody: feedbackEmailOptions.template.htmlMessage,
        attachments: feedbackEmailOptions.template.files,
        from: feedbackEmailOptions.from,
        subject: feedbackEmailOptions.template.subject,
        engageSurveyId: feedbackEmailOptions.engageSurveyId,
      }
    : undefined;

const initJobApplication = (jobId, step, pagination, filter, order) => ({
  type: JobApplicationActionTypes.INIT,
  jobId,
  step,
  pagination,
  filter,
  order,
});

const initJobApplicationSuccess = response => ({
  type: JobApplicationActionTypes.INIT_SUCCESS,
  job: JobPermissionsManager.adaptJobPermissions(response.job),
  steps: response.steps,
  statusList: response.statusList,
  applicationsSummary: response.applicationsSummary,
  summary: response.summary,
  applications: response.applications,
  filters: response.filters,
  applicationsFilters: response.applicationsFilters,
  allCount: getAllApplicationsCount(response.statusList),
  allExcludedCount: response.excludedApplications,
  reprovedCount: getStatusCount(
    JobApplicationCurrentStepType.reproved,
    response.statusList,
  ),
  withdrawnCount: getStatusCount(
    JobApplicationCurrentStepType.withdrawn,
    response.statusList,
  ),
});

const initFiltersSuccess = response => ({
  type: JobApplicationActionTypes.INIT_FILTERS_SUCCESS,
  additionalQuestionsList: response.additionalQuestionsList,
  availableFilters: response.availableFilters,
  coursesList: response.coursesList,
  criterias: response.criterias,
  customTests: response.customTests,
  externalTests: response.externalTests,
  genderList: response.genderList,
  jobAddress: response.jobAddress,
  languageLevelsList: response.languageLevelsList,
  languageList: response.languageList,
  schoolingLevels: response.schoolingLevels,
  messageTypeList: response.messageTypeList,
});

const initJobApplicationReport = jobId => ({
  type: JobApplicationActionTypes.INIT_REPORT,
  jobId,
});

const initJobApplicationCandidate = ({
  jobId,
  applicationId,
  currentStep,
  filter,
  featureFlags,
}) => ({
  type: JobApplicationActionTypes.INIT_CANDIDATE,
  jobId,
  applicationId,
  currentStep,
  filter,
  featureFlags,
});

const initJobApplicationCandidateSuccess = response => {
  const { application, featureFlags } = response;
  const { candidate, ghostCandidate } = application;

  let job = { ...response.job, permissions: application.permissions };
  job = JobPermissionsManager.adaptJobPermissions(job);

  candidate.linkedinProfileUrl = UrlHelper.insertHttp(
    candidate.linkedinProfileUrl,
  );

  if (
    candidate.professionalExperiences &&
    candidate.professionalExperiences.length
  ) {
    candidate.professionalExperiences = candidate.professionalExperiences.map(
      pe => CandidateProfileDetailsHelper.mountTimelineDateLabel(pe),
    );
  }

  if (candidate.formations && candidate.formations.length) {
    candidate.formations = candidate.formations.map(f =>
      CandidateProfileDetailsHelper.mountTimelineDateLabel(f),
    );
  }

  if (candidate) {
    if (candidate.name && candidate.name !== '') {
      candidate.fullName = application.candidate.name;

      if (candidate.lastName && candidate.lastName !== '') {
        candidate.fullName += ` ${candidate.lastName}`;
      }
    }

    if (candidate.canReceiveEmail === undefined) {
      candidate.canReceiveEmail = true;
    }
  }

  if (ghostCandidate) {
    if (ghostCandidate.name && ghostCandidate.name !== '') {
      ghostCandidate.fullName = ghostCandidate.name;

      if (ghostCandidate.lastName && ghostCandidate.lastName !== '') {
        ghostCandidate.fullName += ` ${ghostCandidate.lastName}`;
      }
    }
  }

  application.c3poScore =
    application.c3poScore === null ? undefined : application.c3poScore;

  application.isQuickApply = !!application.quickApply;

  let isCurrentStepPreHireOrFinal = false;

  const hasPreHireStep =
    job.jobSteps &&
    job.jobSteps.some(step => step.type === JobStepTypes.preHire);

  if (application && application.jobStep && application.jobStep.type) {
    isCurrentStepPreHireOrFinal =
      application.jobStep.type === JobStepTypes.preHire ||
      application.jobStep.type === JobStepTypes.final;
  }

  return {
    type: JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS,
    job,
    steps: response.steps,
    application: { ...application, candidate },
    navigation: response.navigation,
    shouldShowPreHireField: hasPreHireStep && isCurrentStepPreHireOrFinal,
    featureFlags,
  };
};

const initDisapprovalReasons = () => ({
  type: JobApplicationActionTypes.INIT_DISAPPROVAL_REASONS,
});

const patchApplicationHiringInformation = (
  jobId,
  applicationId,
  payload,
  currentStep,
  filter,
  pagination,
  messages,
) => ({
  type: JobApplicationActionTypes.PATCH_APPLICATION,
  jobId,
  applicationId,
  payload,
  currentStep,
  filter,
  pagination,
  messages,
});

const getAvailableFilters = () => ({
  type: JobApplicationActionTypes.GET_AVAILABLE_FILTERS,
});

const openFilterMenu = () => ({
  type: JobApplicationActionTypes.OPEN_FILTER_MENU,
});

const changeOrder = order => ({
  type: JobApplicationActionTypes.CHANGE_ORDER,
  order,
});

const reloadApplications = () => ({
  type: JobApplicationActionTypes.RELOAD_APPLICATIONS,
});

const removeApplicationsFilter = fieldToRemove => ({
  type: JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS,
  fieldToRemove,
});

const clearAllFilters = () => ({
  type: JobApplicationActionTypes.CLEAR_ALL_FILTERS,
});

const saveApplicationsFilters = (filterV2Value, messages) => ({
  type: JobApplicationActionTypes.SAVE_APPLICATIONS_FILTERS,
  filterV2Value,
  messages,
});

const getApplicationFilters = () => ({
  type: JobApplicationActionTypes.GET_FILTERS,
});

const closeFilterMenu = filters => ({
  type: JobApplicationActionTypes.CLOSE_FILTER_MENU,
  filters,
});

const jobApplicationFilterChange = filter => ({
  type: JobApplicationActionTypes.CHANGE_FORM_FILTER,
  filter,
});

const jobApplicationHeaderChange = header => ({
  type: JobApplicationActionTypes.CHANGE_HEADER,
  header,
});

const jobApplicationCandidateChange = application => ({
  type: JobApplicationActionTypes.CHANGE_APPLICATION,
  application,
});

const jobApplicationStepChanged = currentStep => ({
  type: JobApplicationActionTypes.STEP_CHANGED,
  currentStep,
});

const jobApplicationPageChanged = pagination => ({
  type: JobApplicationActionTypes.PAGE_CHANGED,
  pagination,
});

const jobApplicationUndoReproveClick = (
  jobId,
  currentStep,
  applications,
  filter,
  pagination,
  messages,
) => ({
  type: JobApplicationActionTypes.APPLICATION_UNDO_REPROVE,
  jobId,
  currentStep,
  applications,
  filter,
  pagination,
  messages,
});

const applicationUndoReproveSuccess = response => ({
  type: JobApplicationActionTypes.APPLICATION_UNDO_REPROVE_SUCCESS,
  jobId: response.jobId,
  currentStep: response.currentStep,
  filter: response.filter,
  pagination: response.pagination,
  steps: response.steps,
  statusList: response.statusList,
  toast: response.toast,
  allCount: getAllApplicationsCount(response.statusList),
  allExcludedCount: response.excludedApplications,
  reprovedCount: getStatusCount(
    JobApplicationCurrentStepType.reproved,
    response.statusList,
  ),
});

const openManageEmailTemplatesModal = () => ({
  type: JobApplicationActionTypes.OPEN_MANAGE_EMAIL_TEMPLATES_MODAL,
});

const closeManageEmailTemplatesModal = () => ({
  type: JobApplicationActionTypes.CLOSE_MANAGE_EMAIL_TEMPLATES_MODAL,
});

const openSendEmailModal = (applications, options) => ({
  type: JobApplicationActionTypes.OPEN_SEND_EMAIL_MODAL,
  applications,
  options,
});

const closeSendEmailModal = () => ({
  type: JobApplicationActionTypes.CLOSE_SEND_EMAIL_MODAL,
});

const changeEmailFormModel = model => ({
  type: JobApplicationActionTypes.CHANGE_EMAIL_FORM_MODEL,
  model,
});

const clearEmailFormModel = () => ({
  type: JobApplicationActionTypes.CLEAR_EMAIL_FORM_MODEL,
});

const changeEmailTemplate = template => ({
  type: JobApplicationActionTypes.CHANGE_EMAIL_TEMPLATE,
  template,
});

const loadSelectedTemplate = id => ({
  type: JobApplicationActionTypes.LOAD_SELECTED_TEMPLATE,
  id,
});

const sendEmailToSingleApplication = (jobId, payload, messages) => ({
  type: JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION,
  jobId,
  payload,
  messages,
});

const sendEmailToApplications = ({
  jobId,
  currentStep,
  filter,
  pagination,
  payload,
  messages,
}) => ({
  type: JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS,
  jobId,
  currentStep,
  filter,
  pagination,
  payload,
  messages,
});

const openDisapproveJobModal = (applications, options) => ({
  type: JobApplicationActionTypes.OPEN_DISAPPROVE_JOB_MODAL,
  applications,
  options,
});

const closeDisapproveJobModal = () => ({
  type: JobApplicationActionTypes.CLOSE_DISAPPROVE_JOB_MODAL,
});

const changeApplicationFavorite = (
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  favoriteCount,
  currentStep,
  filter,
  pagination,
) => ({
  type: JobApplicationActionTypes.CHANGE_APPLICATION_FAVORITE,
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  favoriteCount,
  currentStep,
  filter,
  pagination,
});

const changeApplicationCandidateFavorite = (
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  favoriteCount,
  currentStep,
) => ({
  type: JobApplicationActionTypes.CHANGE_APPLICATION_CANDIDATE_FAVORITE,
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  favoriteCount,
  currentStep,
});

const changeApplicationLike = (
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  value,
  currentStep,
  messages,
) => ({
  type: JobApplicationActionTypes.CHANGE_APPLICATION_LIKE,
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  value,
  currentStep,
  messages,
});

const changeApplicationCriteriaRating = (
  jobId,
  applicationId,
  criteriaId,
  candidateId,
  rating,
) => ({
  type: JobApplicationActionTypes.CHANGE_CRITERIA_RATING,
  jobId,
  applicationId,
  criteriaId,
  candidateId,
  rating,
});

const changeCurriculumDataTabChange = (jobId, applicationId, tabIndex) => ({
  type: JobApplicationActionTypes.CHANGE_CURRICULUM_DATA_TAB,
  jobId,
  applicationId,
  tabIndex,
});

const changeCommentForm = modelForm => ({
  type: JobApplicationActionTypes.CHANGE_FORM_COMMENT,
  modelForm,
});

const postCommentForm = (
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  modelForm,
  currentStep,
  messages,
) => ({
  type: JobApplicationActionTypes.POST_COMMENT,
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  modelForm: {
    ...modelForm,
    commentary: modelForm[`commentary-${candidateId}`],
  },
  currentStep,
  messages,
});

const postAttachmentForm = (
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  modelForm,
  currentStep,
  messages,
) => ({
  type: JobApplicationActionTypes.POST_ATTACHMENT,
  jobId,
  applicationId,
  candidateId,
  currentCandidateStepId,
  modelForm: {
    ...modelForm,
    commentary: modelForm[`commentary-${candidateId}`],
  },
  currentStep,
  messages,
});

const curriculumDisqualifyApplication = ({
  jobId,
  applicationId,
  feedbackEmailOptions,
  search,
  messages,
  isGhost,
  disapprovalReason,
  disapprovalReasonObservation,
}) => {
  const shouldSendEmail =
    feedbackEmailOptions &&
    feedbackEmailOptions.template &&
    feedbackEmailOptions.template.template &&
    !isGhost;

  const feedbackPayload = shouldSendEmail
    ? {
        from: feedbackEmailOptions.from,
        subject: feedbackEmailOptions.template.subject,
        emailBody: feedbackEmailOptions.template.htmlMessage,
        attachments: feedbackEmailOptions.template.files,
        applicationIds: [applicationId],
        engageSurveyId: feedbackEmailOptions.engageSurveyId,
      }
    : undefined;

  return {
    type: JobApplicationActionTypes.CURRICULUM_DISQUALIFY_APPLICATION,
    jobId,
    applicationId,
    feedbackPayload,
    search,
    disapprovalReason,
    disapprovalReasonObservation,
    messages,
    toast: messages
      ? buildToast(messages.disqualifyingCandidate, ToastTypes.info)
      : undefined,
  };
};

const curriculumDisqualifyApplicationSuccess = ({
  jobId,
  applicationId,
  messages,
  disapprovalReason,
}) => ({
  type: JobApplicationActionTypes.CURRICULUM_DISQUALIFY_APPLICATION_SUCCESS,
  jobId,
  applicationId,
  toast: messages
    ? buildToast(messages.candidateDisqualified, ToastTypes.success)
    : undefined,
  disapprovalReason,
});

const curriculumChangeApplicationStep = ({
  jobId,
  applicationId,
  application,
  filter,
  search,
  messages,
  nextStep,
}) => ({
  type: JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP,
  jobId,
  applicationId,
  application,
  filter,
  search,
  messages,
  nextStep,
  toast: messages
    ? buildToast(messages.movingCandidate, ToastTypes.info)
    : undefined,
});

const curriculumChangeApplicationStepSuccess = ({
  jobId,
  applicationId,
  nextStep,
  feedbackRequest,
  messages,
}) => ({
  type: JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP_SUCCESS,
  jobId,
  applicationId,
  feedbackRequest,
  nextStep,
  toast: messages
    ? buildToast(messages.candidateMoved, ToastTypes.success)
    : undefined,
});

const curriculumUndoReprove = ({
  jobId,
  applicationId,
  nextStep,
  search,
  messages,
}) => ({
  type: JobApplicationActionTypes.CURRICULUM_UNDO_REPROVE,
  jobId,
  applicationId,
  nextStep,
  search,
  messages,
  toast: messages
    ? buildToast(messages.undoingCandidateReproval, ToastTypes.info)
    : undefined,
});

const curriculumUndoReproveSuccess = ({
  jobId,
  applicationId,
  nextStep,
  messages,
}) => ({
  type: JobApplicationActionTypes.CURRICULUM_UNDO_REPROVE_SUCCESS,
  jobId,
  applicationId,
  nextStep,
  toast: messages
    ? buildToast(messages.candidateReprovalUndone, ToastTypes.success)
    : undefined,
});

const changeCriteriasFilter = filter => ({
  type: JobApplicationActionTypes.CHANGE_CRITERIAS_FILTER,
  filter,
});

const getAllApplicationsWithNoFeedback = jobId => ({
  type: JobApplicationActionTypes.GET_ALL_APPLICATIONS_WITH_NO_FEEDBACK,
  jobId,
});

const getFinalApplicationsCount = jobId => ({
  type: JobApplicationActionTypes.GET_FINAL_APPLICATIONS_COUNT,
  jobId,
});

const sendMessageReadReceipt = (threadId, messageId, jobId, applicationId) => ({
  type: JobApplicationActionTypes.SEND_MESSAGE_READ_RECEIPT,
  threadId,
  messageId,
  jobId,
  applicationId,
});

const patchThreadAllowReply = (threadId, allowReply) => ({
  type: JobApplicationActionTypes.PATCH_THREAD_ALLOW_REPLY,
  threadId,
  allowReply,
});

const getApplicationEmailMessages = ({ jobId, applicationId }) => ({
  type: JobApplicationActionTypes.GET_APPLICATION_EMAIL_MESSAGES,
  jobId,
  applicationId,
});

const saveClickWhatsAppLink = ({ applicationId, jobId }) => ({
  type: JobApplicationActionTypes.SAVE_CLICK_WHATSAPP_LINK,
  jobId,
  applicationId,
});

export const selectAddressSuggestion = ({ suggestion }) => ({
  type: JobApplicationActionTypes.SELECT_ADDRESS_SUGGESTION,
  suggestion,
});

export const clearAddressSuggestions = () => ({
  type: JobApplicationActionTypes.CHANGE_FORM_ADDRESS_CLEAR_SUGGESTIONS,
});

export const changeAddressSuggestions = input => ({
  type: JobApplicationActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS,
  input,
});

export const getAddressSuggestionsSuccess = suggestions => {
  const hasSuggestions =
    suggestions && suggestions.body && suggestions.body.items;

  return {
    type: JobApplicationActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS,
    suggestions: hasSuggestions
      ? suggestions.body.items.map(item => adaptGeolocationFromHere(item))
      : [],
  };
};

export const getTimeline = payload => ({
  type: JobApplicationActionTypes.GET_TIMELINE,
  payload,
});

const updateUserComment = payload => ({
  type: JobApplicationActionTypes.UPDATE_USER_COMMENT,
  payload,
});

const closeMoveApplicationModal = () => ({
  type: JobApplicationActionTypes.CLOSE_MOVE_APPLICATION_MODAL,
});

const moveRequested = ({
  jobId,
  nextStep,
  currentStep,
  applications,
  messages,
}) => ({
  type: JobApplicationActionTypes.MOVE,
  jobId,
  nextStep: {
    id: nextStep.id,
    type: nextStep.type,
    name: nextStep.name,
  },
  currentStep: {
    id: currentStep.id,
    type: currentStep.type,
  },
  applications: applications.map(app => ({
    id: app.id,
    currentStepId: app.jobStep.id,
    status: app.status,
  })),
  messages: {
    succeeded: messages.succeeded,
    failed: messages.failed,
  },
  toast: buildToast(messages.requested, ToastTypes.info),
});

const moveSucceeded = ({
  jobId,
  nextStep,
  currentStep,
  applications,
  messages,
}) => ({
  type: JobApplicationActionTypes.MOVE_SUCCESS,
  jobId,
  nextStep,
  currentStep,
  applications,
  toast: buildToast(messages.succeeded, ToastTypes.success),
});

const moveFailed = (message, applications) => ({
  type: JobApplicationActionTypes.MOVE_FAIL,
  toast: buildToast(message, ToastTypes.error),
  applications,
});

const hiringFeedbackRequested = ({ context, feedbackType }) => ({
  type: JobApplicationActionTypes.HIRING_FEEDBACK_REQUESTED,
  context,
  feedbackType,
});

const disqualifyRequested = ({
  jobId,
  nextStep,
  currentStep,
  applications,
  reason,
  observation,
  feedbackEmailOptions,
  messages,
}) => ({
  type: JobApplicationActionTypes.DISQUALIFY,
  jobId,
  currentStep: {
    id: currentStep.id,
    type: currentStep.type,
  },
  applications: applications.map(app => ({
    id: app.id,
    currentStepId: app.jobStep.id,
    status: app.status,
  })),
  reason,
  observation,
  feedbackMessage: buildFeedbackMessage({
    nextStep,
    feedbackEmailOptions,
    applicationIds: applications.map(app => app.id),
    ghostApplicationIds: applications
      .filter(app => app.isGhost)
      .map(app => app.id),
  }),
  messages: {
    succeeded: messages.succeeded,
    failed: messages.failed,
  },
  toast: buildToast(messages.requested, ToastTypes.info),
});

const disqualifySucceeded = message => ({
  type: JobApplicationActionTypes.DISQUALIFY_SUCCESS,
  toast: buildToast(message, ToastTypes.success),
});

const disqualifyFailed = (message, applications) => ({
  type: JobApplicationActionTypes.DISQUALIFY_FAIL,
  toast: buildToast(message, ToastTypes.error),
  applications,
});

const bulkMoveRequested = ({ nextStep, messages }) => ({
  type: JobApplicationActionTypes.BULK_MOVE,
  nextStep: {
    id: nextStep.id,
  },
  messages: {
    failed: messages.failed,
  },
});

const bulkMoveSucceeded = () => ({
  type: JobApplicationActionTypes.BULK_MOVE_SUCCESS,
});

const bulkMoveFailed = message => ({
  type: JobApplicationActionTypes.BULK_MOVE_FAIL,
  toast: buildToast(message, ToastTypes.error),
});

const bulkDisqualifyRequested = ({
  reason,
  observation,
  feedbackEmailOptions,
  messages,
}) => ({
  type: JobApplicationActionTypes.BULK_DISQUALIFY,
  reason,
  observation,
  feedbackMessage: buildFeedbackMessage({
    feedbackEmailOptions,
    nextStep: { type: JobApplicationCurrentStepType.reproved },
    applicationIds: [],
    ghostApplicationIds: [],
  }),
  messages: {
    failed: messages.failed,
  },
});

const bulkDisqualifySucceeded = () => ({
  type: JobApplicationActionTypes.BULK_DISQUALIFY_SUCCESS,
});

const bulkDisqualifyFailed = message => ({
  type: JobApplicationActionTypes.BULK_DISQUALIFY_FAIL,
  toast: buildToast(message, ToastTypes.error),
});

const openSendWhatsAppBusinessModal = applications => ({
  type: JobApplicationActionTypes.OPEN_SEND_WHATSAPP_BUSINESS_MODAL,
  applications,
});

const closeSendWhatsAppBusinessModal = () => ({
  type: JobApplicationActionTypes.CLOSE_SEND_WHATSAPP_BUSINESS_MODAL,
});

const getWhatsAppBusinessAvailable = companyEmail => ({
  type: JobApplicationActionTypes.GET_WHATSAPP_BUSINESS_AVAILABLE,
  companyEmail,
});

const initCandidateCurriculum = (candidateId, groupFilters) => ({
  type: JobApplicationActionTypes.INIT_CANDIDATE_CURRICULUM,
  candidateId,
  groupFilters,
});

const getCandidateDocuments = (applicationId, documentType) => ({
  type: JobApplicationActionTypes.GET_CANDIDATE_DOCUMENTS,
  payload: {
    applicationId,
    documentType,
  },
});

const getCandidateDocumentSignedUrl = (applicationId, documentId) => ({
  type: JobApplicationActionTypes.GET_CANDIDATE_DOCUMENT_SIGNED_URL,
  payload: {
    applicationId,
    documentId,
  },
});

const openMoveApplicationModal = errorMessage => ({
  type: JobApplicationActionTypes.OPEN_MOVE_APPLICATION_MODAL,
  errorMessage,
});

const getFeedbackAnalyticsInfo = jobId => ({
  type: FeebackAnalyticsInfoTypes.GET_INFO,
  payload: { jobId },
});

const getContentMessage = (threadId, messageId) => ({
  type: JobApplicationActionTypes.GET_CONTENT_MESSAGE,
  threadId,
  messageId,
});

export {
  initJobApplication,
  initJobApplicationSuccess,
  initFiltersSuccess,
  initJobApplicationReport,
  initJobApplicationCandidate,
  initJobApplicationCandidateSuccess,
  initDisapprovalReasons,
  getAvailableFilters,
  openFilterMenu,
  closeFilterMenu,
  jobApplicationFilterChange,
  jobApplicationHeaderChange,
  jobApplicationCandidateChange,
  jobApplicationStepChanged,
  jobApplicationPageChanged,
  jobApplicationUndoReproveClick,
  openManageEmailTemplatesModal,
  closeManageEmailTemplatesModal,
  openSendEmailModal,
  closeSendEmailModal,
  changeEmailFormModel,
  clearEmailFormModel,
  changeEmailTemplate,
  loadSelectedTemplate,
  sendEmailToSingleApplication,
  sendEmailToApplications,
  openDisapproveJobModal,
  closeDisapproveJobModal,
  changeApplicationFavorite,
  changeApplicationCandidateFavorite,
  changeApplicationLike,
  changeApplicationCriteriaRating,
  changeCurriculumDataTabChange,
  changeCommentForm,
  postCommentForm,
  postAttachmentForm,
  curriculumDisqualifyApplication,
  curriculumChangeApplicationStep,
  curriculumUndoReprove,
  changeCriteriasFilter,
  getAllApplicationsWithNoFeedback,
  getFinalApplicationsCount,
  sendMessageReadReceipt,
  patchThreadAllowReply,
  patchApplicationHiringInformation,
  applicationUndoReproveSuccess,
  getApplicationEmailMessages,
  changeOrder,
  reloadApplications,
  saveClickWhatsAppLink,
  updateUserComment,
  closeMoveApplicationModal,
  moveRequested,
  moveSucceeded,
  moveFailed,
  hiringFeedbackRequested,
  disqualifyRequested,
  disqualifySucceeded,
  disqualifyFailed,
  saveApplicationsFilters,
  removeApplicationsFilter,
  clearAllFilters,
  getApplicationFilters,
  bulkMoveRequested,
  bulkMoveSucceeded,
  bulkMoveFailed,
  bulkDisqualifyRequested,
  bulkDisqualifySucceeded,
  bulkDisqualifyFailed,
  openSendWhatsAppBusinessModal,
  closeSendWhatsAppBusinessModal,
  getWhatsAppBusinessAvailable,
  initCandidateCurriculum,
  getCandidateDocuments,
  getCandidateDocumentSignedUrl,
  openMoveApplicationModal,
  getFeedbackAnalyticsInfo,
  curriculumChangeApplicationStepSuccess,
  curriculumDisqualifyApplicationSuccess,
  curriculumUndoReproveSuccess,
  getContentMessage,
};

export const getOffers = ({ jobId }) => ({
  type: JobApplicationActionTypes.GET_OFFERS,
  payload: {
    jobId,
  },
});

export const getSurveys = () => ({
  type: JobApplicationActionTypes.GET_ENGAGE_SURVEYS,
});
